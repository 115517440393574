<style scoped>
@import "../../assets/css/workplatform2.css";
.menu-item {
  background: #25272a;
  overflow-y: scroll;
  scrollbar-width: none; /* FireFox */
  -ms-overflow-style: none; /*IE10+*/
}
.menu-item::-webkit-scrollbar {
  display: none;
}
.ivu-menu-vertical .ivu-menu-item,
.ivu-menu-vertical .ivu-menu-submenu-title {
  padding: 8px 0;
  text-align: center;
}
.menu-item span {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
  transition: width 0.2s ease 0.2s;
  font-size: 14px;
}
.menu-item i {
  transform: translateX(0px);
  transition: font-size 0.2s ease, transform 0.2s ease;
  vertical-align: middle;
  font-size: 14px;
}
.menu-item li {
  padding: 11px 0;
  text-align: center;
  position: relative;
  cursor: pointer;
  z-index: 1;
  transition: all 0.2s ease-in-out;
}

.ivu-dropdown-item:hover {
  background: #40434a;
  color: #fff;
  opacity: 0.9;
}
.platform-submenu {
  width: 48px;
}
.icon-btn {
  cursor: pointer;
  height: 25px;
  line-height: 25px;
}
.icon-btn:hover {
  background-color: rgba(150, 150, 150, 0.5);
}
.font-white{
  color:#fff;
}
.platform {
  display: block;
  padding: 10px 0;
  /* color: #fff; */
  border-radius: 3px;
}
.platform:hover {
  color: #5cadff;
  border-color: #5cadff;
}
.platform-actived {
  color: #2d8cf0;
  border-color: #2b85e4;
}
</style>
<template>
  <div id="content_body">
    <Layout :style="{minHeight: '100vh'}">
      <Sider width="50" :style="{background:'#25272A'}" style="z-index: 999">
        <div>

          <div class="logo" @click="handleGoHome">
            <img v-if="defaultPublisherId===13" src="../../assets/images/青岛地铁深色背景.png">
            <img v-else-if="defaultPublisherId===18" src="../../assets/images/北京地铁资源深色背景.png">
            <img v-else-if="defaultPublisherId===21" src="../../assets/images/北京轨道深色背景.png">
            <img v-else src="../../assets/images/oohforce深色背景.png">
          </div>

          <div class="logo-divider">
            <Divider style="background-color: #414141; margin: 0px;" />
          </div>

          <Poptip class="m-l-10" trigger="hover" placement="right">
            <Icon size="26" color="#2d8cf0" type="ios-apps-outline" />
            <div slot="content" class="menu-change-platform">
              <Row :gutter="8">
                <i-col
                  span="12"
                  class="m-b-5"
                  v-for="item in platformMenuArray"
                  :key="'p_'+item.platformId"
                >
                  <a
                    class="text-center platform font-white"
                    :class="platformMenu.platformId===item.platformId?'platform-actived':''"
                    href="javascript:void(0)"
                    @click="handleSwitchPlatform(item)"
                  >
                    <h2>{{item.platformName}}</h2>
                  </a>
                </i-col>
              </Row>
            </div>
          </Poptip>

          <Menu
            ref="menu_box"
            id="menu_box"
            class="menu-item"
            :active-name="defalutMenu"
            theme="dark"
            width="auto"
            @on-select="handleChangeMenu"
            v-if="this.menuList[0].meta.group!==-1"
          >
            <template v-for="(item,index) in showMenuList">
              <MenuItem
                v-if="item.children.length===1"
                :key="'menu_'+index"
                :id="item.children[0].meta.group"
                :name="item.children[0].meta.group"
                :to="item.children[0].path">
                <span>{{item.meta.title}}</span>
              </MenuItem>
              <MenuItem
                v-else
                :id="item.children[0].meta.group"
                :name="item.children[0].meta.group"
                :to="item.children[0].path"
                :key="'menu2_'+index"
              >
              <Dropdown
                transfer
                class="platform-submenu"
                placement="right-start"
                @on-click="handleChangeChildMenu"
              >
                <span>{{item.meta.title}}</span>
                <DropdownMenu slot="list">
                  <DropdownItem
                    v-for="child in item.children"
                    :name="child.name"
                    :key="'child_'+child.meta.menuId"
                  >
                    <span style="font-size:14px">{{child.meta.title}}</span>
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
              </MenuItem>

            </template>
          </Menu>

          <Tooltip class="m-l-10 p-t-10" content="显示更多菜单" placement="right-start" v-if="menuList.length>MAX_MENU_ROW">
            <a @click="switchMenuModal=true" class="text-14" style="color:rgba(255, 255, 255, 0.7)">更多</a>
          </Tooltip>
        </div>

        <div class="menu-footer">
          <Tooltip v-if="publisherArray.length>1" content="切换租户" placement="right-start" class="workplatform-lock">
            <Icon
                size="24"
                type="md-git-network"
                class="workplatform-lock-icon"
                @click="switchPublisherModal=true"
              />
          </Tooltip>

          <div class="workplatform-lock">
            <Poptip trigger="hover" placement="right">
              <Icon
                  size="26"
                  type="ios-shirt-outline"
                  class="workplatform-lock-icon"
                />

              <span slot="title">切换主题</span>
              <div slot="content">
                <RadioGroup v-model="theme" @on-change="handleChangeTheme">
                    <Radio label="white" border>月光白</Radio>
                    <Radio label="dark" border>暗夜黑</Radio>
                </RadioGroup>
              </div>
            </Poptip>
          </div>

          <div class="workplatform-lock" @click="handleLogout">
            <Icon type="md-power" size="25" class="workplatform-lock-icon"/>
          </div>
          <div class="remark">{{version}}</div>
        </div>
      </Sider>

      <Layout>
        <Content>
          <router-view></router-view>
        </Content>
      </Layout>
    </Layout>

    <!-- 弹窗切换 -->
    <Modal
      v-model="switchPlatformModal"
      :footer-hide="true"
      width="800"
    >
      <p
        slot="header"
        class="text-center"
      >切换平台</p>
      <Row :gutter="8">
        <i-col
          span="8"
          class="m-b-5"
          v-for="item in platformMenuArray"
          :key="'p_'+item.platformId"
        >
          <a
            class="text-center platform"
            :class="platformMenu.platformId===item.platformId?'platform-actived':''"
            href="javascript:void(0)"
            @click="handleSwitchPlatform(item)"
          >
            <h2>{{item.platformName}}</h2>
          </a>
        </i-col>
      </Row>
    </Modal>

    <Modal
      v-model="switchPublisherModal"
      :footer-hide="true"
      width="800"
    >
      <p
        slot="header"
        class="text-center"
      >切换运营方</p>
      <Row :gutter="8">
        <i-col
          span="12"
          class="m-b-5"
          v-for="item in publisherArray"
          :key="'p_'+item.id"
        >
          <a
            class="text-center platform"
            :class="defaultPublisherId===item.id?'platform-actived':''"
            href="javascript:void(0)"
            @click="handleSwitchPublisher(item)"
          >
            <h2>{{item.name}}</h2>
          </a>
        </i-col>
      </Row>
    </Modal>

    <Modal v-model="switchMenuModal" :footer-hide="true" width="800">
      <p slot="header" class="text-center">切换至{{platformMenu.platformName}}其他菜单</p>
      <Row :gutter="8">
        <i-col span="4" class="m-b-5" v-for="(item,index) in menuList" :key="'m_'+index">
          <a class="text-center platform"
            :class="defalutMenu===item.meta.group?'platform-actived':''"
            @click="handleChangeOtherMenu(item)">
            <h2>{{item.meta.title}}</h2>
          </a>
        </i-col>
      </Row>
    </Modal>

    <update-notice />
  </div>
</template>

<script>
import { getStorage, setStorage } from '@/utils/storage'
import updateNotice from './UpdateNotice.vue'
import { saveinstance } from '@/api/sys/event'
const packages = require('../../../package.json')

export default {
  components: {
    updateNotice
  },
  data () {
    return {
      // menuList: [],
      version: 'v ' + packages.version,
      MAX_MENU_ROW: 15,
      platformMenuArray: getStorage('userMenus'),
      platformMenu: getStorage('userShowMenu'),
      switchPlatformModal: false,
      switchPublisherModal: false,
      switchMenuModal: false,
      defaultPublisherId: this.$store.getters.token.userInfo.publisherId,
      publisherArray: this.$store.getters.token.userInfo.publishers,

      theme: 'dark'
    }
  },
  computed: {
    menuList () {
      const currentMenuArray = this.$store.state.sys.menuArray
      // 北京轨交需要前置运维菜单
      if (this.platformMenu.platformId === 9 && this.$store.getters.token.userInfo.publisherId === 21) {
        const topMenuIndex = currentMenuArray.findIndex(x => x.meta.title === '运维')
        // 如果运维菜单未排在第一位，那么执行位置交换
        if (topMenuIndex > 0) {
          currentMenuArray[topMenuIndex] = currentMenuArray.splice(0, 1, currentMenuArray[topMenuIndex])[0]
          this.updateDefaultMenu(currentMenuArray)
        }
      }

      return currentMenuArray
    },
    showMenuList () {
      if (this.menuList.length > this.MAX_MENU_ROW) {
        // 如果当前菜单内未包含选中的值， 那么替换最后一条数据
        const newMenuList = this.menuList.slice(0, this.MAX_MENU_ROW)
        if (!newMenuList.find(x => x.meta.group === this.defalutMenu)) {
          const replaceIndex = newMenuList.length - 1
          newMenuList.splice(replaceIndex, replaceIndex, this.menuList.find(x => x.meta.group === this.defalutMenu))
          this.$nextTick(() => {
            this.$refs.menu_box.updateActiveName()
          })
        }
        return newMenuList
      } else {
        return this.menuList
      }
    },
    defalutMenu: {
      get () {
        return this.$store.state.sys.currentMenu
      },
      set (val) {
        this.$store.commit('set_current_menu', val)
      }

    }
  },
  created () {
    this.initData()
    this.checkMenuRow()
  },
  methods: {
    initData () {
      // this.menuList = getStorage('dynamicMenus')
      this.defalutMenu = this.defalutMenu || this.menuList[0].meta.group
      this.saveinstance()
      const _self = this
      this.$nextTick(() => {
        window.onresize = () => {
          _self.checkMenuRow()
        }
      })

      // 设置主题颜色的值
      this.$parent.loadTheme()
      this.theme = localStorage.getItem('oohforce-workbench-theme') || 'dark'
    },
    checkMenuRow () {
      const currentPageHeight = document.documentElement.clientHeight
      if (currentPageHeight < 700) {
        this.MAX_MENU_ROW = 5
      } else if (currentPageHeight < 900) {
        this.MAX_MENU_ROW = 10
      } else {
        this.MAX_MENU_ROW = 15
      }
    },
    handleLogout () {
      let loginAddress = '/login'
      if (process.env.NODE_ENV === 'production') {
        loginAddress = process.env.VUE_APP_LOGIN_ADDRESS
        // 生产环境需要跳转至不同的登录页
        if (process.env.VUE_APP_BUILD === 'prod') {
          if (this.defaultPublisherId === 13) {
            loginAddress = process.env.VUE_APP_LOGIN_ADDRESS2
          } else if (this.defaultPublisherId === 26) {
            loginAddress = process.env.VUE_APP_LOGIN_ADDRESS3
          }
        }
      }

      this.$store.dispatch('LogOut').then(res => {
        // 清理当前打开导航栏
        // this.$store.commit('SET_MENUGROUP', '')
        // this.$store.commit('set_current_menu', {})
        location.href = loginAddress
      })
    },
    // 主菜单选中事件
    handleChangeMenu (name) {
      // name为主菜单id
      this.$store.commit('set_current_menu', name)
      this.saveinstance()
    },
    // 子菜单点击事件
    handleChangeChildMenu (name) {
      // name为路由名称
      this.$router.push({ name: name })
    },
    handleSwitchPlatform (item) {
      this.switchPlatformModal = false
      if (item.platformId !== this.platformMenu.platformId) {
        // 更新菜单权限
        var auths = []
        this.getAuthSignData(item.menuList, auths)
        setStorage('userAuth', auths)
        setStorage('userShowMenu', item)
        this.$store.commit('set_current_menu', 0)
        window.location.href = '/'
      }
    },
    handleSwitchPublisher (item) {
      if (item.id !== this.defaultPublisherId) {
        // 更新菜单权限
        this.$store.dispatch('changepublisher', { publisherId: item.id }).then(() => {
          this.showMessage = false
          this.switchPublisherModal = false
          window.location.href = '/'
        }).catch((error) => {
          this.showMessage = true
          this.switchPublisherModal = false
          this.message = error
        })
      }
    },
    getAuthSignData (sourceData, targetData = []) {
      sourceData.forEach(item => {
        if (item.children && item.children.length) {
          if (item.code !== '') {
            targetData.push(item.code)
          }
          this.getAuthSignData(item.children, targetData)
        } else {
          if (item.code !== '') {
            targetData.push(item.code)
          }
        }
      })
    },
    handleChangeOtherMenu (item) {
      this.handleChangeMenu(item.meta.group)
      this.$router.push({ name: item.children[0].name })
      this.switchMenuModal = false
    },
    // 调用埋点
    saveinstance () {
      const data = {
        code: 'menu',
        name: '菜单'
      }
      const currentMenuInfo = this.menuList.filter(x => x.meta.group === this.defalutMenu)[0]
      data.data = JSON.stringify({
        relateId: this.defalutMenu,
        name: currentMenuInfo.meta.title,
        platformId: this.platformMenu.platformId,
        platformName: this.platformMenu.platformName
      })
      saveinstance(data).then(res => {
      })
    },
    updateDefaultMenu (currentMenuArray) {
      this.$store.commit('set_current_menu', currentMenuArray[0].meta.group)
      this.$router.push({ name: currentMenuArray[0].children[0].name })
    },
    handleGoHome () {
      this.$router.push('/')
    },
    handleChangeTheme () {
      // 主题需要保存在浏览器中
      localStorage.setItem('oohforce-workbench-theme', this.theme)
      // 立即执行主题切换
      this.$parent.loadTheme()
    }
  }
}
</script>
